import React, { Component } from "react";
import moment from 'moment-timezone';
import { Form, Button, Col, Row, Card, Alert } from "react-bootstrap";
import Header from '../components/Header';
import Meta from '../components/Meta';
import Wallet from '../components/Wallet';
import PuzzleCards from '../components/Cards';

export default class Puzzle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      seed: '',
      seed1: '',
      seed2: '',
      seed3: '',
      message: '',
      errors: [],
      walletId: '',
      errorMessage: '',
      puzzleDay: 0,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.hasError = this.hasError.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    const time = new Date().toISOString();

    if (
      moment(time).isSameOrAfter(moment(`${process.env.REACT_APP_DAY_1}`)) &&
      moment(time).isBefore(moment(`${process.env.REACT_APP_DAY_2}`))
    ) {
      this.setState({ puzzleDay: 1 });
    } else if (
      moment(time).isSameOrAfter(moment(`${process.env.REACT_APP_DAY_2}`)) &&
      moment(time).isBefore(moment(`${process.env.REACT_APP_DAY_3}`))
    ) {
      this.setState({ puzzleDay: 2 });
    } else if (moment(time).isSameOrAfter(moment(`${process.env.REACT_APP_DAY_3}`))) {
      this.setState({ puzzleDay: 3 });
    }
  }

  hasError = (key) => {
    return this.state.errors.indexOf(key) !== -1;
  }

  handleInputChange = async(event) => {
    this.setState({
      message: '',
      errorMessage: '',
    });
    var key = event.target.name;
    var value = event.target.value;
    var obj = {};
    obj[key] = value;
    this.setState(obj);
  }

  onSubmit = async (event) => {
    event.preventDefault();

    //VALIDATE
    var errors = [];

    if (window.ethereum) { // if Metamask installed
      try {
        const accounts = await window.ethereum.request({ method: "eth_accounts" }) //get Metamask wallet
        if (accounts.length) { //if a Metamask account is connected
          this.state.walletId = accounts[0];
        }
      } catch {
        this.setState({ errorMessage: 'Please Connect your Metamask wallet.'})
        return false;
      }
    }

    // seed
    if (this.state.seed === "" ||
        this.state.seed1 === "" ||
        this.state.seed2 === ""||
        (this.state.seed3 === "" && this.state.puzzleDay === 3)
      ) {
      errors.push("seed");
    }

    if (this.state.username === "") {
      errors.push("username");
    }

    this.setState({
      message: '',
      errorMessage: '',
      errors,
    });

    if (errors.length > 0) {
      return false;
    } else if (this.state.walletId === '') {
      this.setState({ errorMessage: 'Please Connect your Metamask wallet.'})
      return false;
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/seeds`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: this.state.username,
          seed: `${this.state.seed} ${this.state.seed1} ${this.state.seed2} ${this.state.seed3}`,
          walletId: this.state.walletId,
        }),
      })
        .then(response => {
          return response.text();
        })
        .then((data) => {
          if (data.includes('You won')) {
            this.setState({message: data});
          } else {
            this.setState({errorMessage: data});
          }
          this.setState({ seed: '', seed1: '', seed2: '', seed3: '', username: '' });
          return data;
        })
      }
  };

  render() {
    let isCurrentDay1 = false;
    let isCurrentDay2 = false;
    let isCurrentDay3 = false;

    if (this.state.puzzleDay === 1) {
      isCurrentDay1 = true;
    } else if (this.state.puzzleDay === 2) {
      isCurrentDay2 = true;
    } else if (this.state.puzzleDay === 3) {
      isCurrentDay3 = true;
    }

    return (
      <div className="App">
        <Wallet />
        <Meta title="Puzzle" />
        <Header head="Puzzle"  description='' />
        {this.state.message && <Alert variant="success" closeButton>{this.state.message}</Alert>}
        {this.state.errorMessage && <Alert variant="danger" closeButton>{this.state.errorMessage}</Alert>}
        <PuzzleCards index="1" />
        {(isCurrentDay2 || isCurrentDay3) && <PuzzleCards index="2" />}
        {isCurrentDay3 && <PuzzleCards index="3" />}
        <Form>
          <Form.Group as={Row} controlId="validationCustom01" className="formtext">
            <Form.Label column sm="2">
              Nifty Gateway Username
            </Form.Label>

            <Col sm="8">
              <Form.Control
                type="username"
                className={
                  this.hasError("username")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="username"
                value={this.state.username}
                onChange={this.handleInputChange}
              />
            </Col>
            <br />
          </Form.Group>

          <Form.Group as={Row} controlId="validationCustom01" className="formtext">
            <Form.Label column sm="2">
              Seed
            </Form.Label>

            <Col sm="2">
              <Form.Control
                type="text"
                className={
                  this.hasError("seed")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="seed"
                value={this.state.seed}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col sm="2">
              <Form.Control
                type="text"
                className={
                  this.hasError("seed")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="seed1"
                value={this.state.seed1}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col sm="2">
              <Form.Control
                type="text"
                className={
                  this.hasError("seed")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="seed2"
                value={this.state.seed2}
                onChange={this.handleInputChange}
              />
            </Col>
            {isCurrentDay3 &&<Col sm="2">
              <Form.Control
                type="text"
                className={
                  this.hasError("seed")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="seed3"
                value={this.state.seed3}
                onChange={this.handleInputChange}
              />
            </Col>}
          </Form.Group>
          <Form.Group>
            <Button className="btn btn-success"  type="submit" onClick={this.onSubmit}>
              Submit
            </Button>
          </Form.Group>
        </Form>
      </div>
    );
  }
}
