import { Button, Container } from "react-bootstrap";
import { Redirect, Route, Switch } from "react-router-dom";
import Museum from "./assets/lowresvideos/Museum2.mp4";
import NextIcon from "./assets/icons/next.png";
// Layout
import Layout from "./layout/Layout";

// pages
import Leaderboard from "./pages/Leaderboard";
import NotFound from "./pages/NotFound";
import Puzzle from "./pages/Puzzle";
import Newyork from "./pages/Newyork";
import Miami from "./pages/Miami";
import Convergence from "./pages/Convergence";
import ReactPlayer from "react-player";
import "./App.css";
import React from "react";
import ReactDOM from "react-dom";
import useWindowDimensions from "./useWindowDimension";

function App() {
  const [visibleVideo, setVisibleVideo] = React.useState(true);

  const [openingDisplay, setOpeningDisplay] = React.useState("block");
  const { height, width } = useWindowDimensions();

  React.useEffect(() => {
    if (!visibleVideo) {
      localStorage.setItem("visibleVideo", "hidden");
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  }, [visibleVideo]);

  React.useEffect(() => {
    if (!localStorage.getItem("visibleVideo")) {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    }
    if (width < 600) {
      setOpeningDisplay("none");
      localStorage.setItem("visibleVideo", "hidden");
    }
  }, []);

  const handleVideoEnded = () => {
    setVisibleVideo(false);
  };

  const handleSkip = () => {
    setVisibleVideo(false);
    localStorage.setItem("visibleVideo", "hidden");
  };

  return visibleVideo === false ||
    localStorage.getItem("visibleVideo") === "hidden" ||
    openingDisplay === "none" ? (
    <Layout>
      <Container>
        <Switch>
          <Route exact path="/">
            <Redirect to="/convergence" />
          </Route>
          <Route path="/puzzle" component={Puzzle} />
          <Route path="/thankyounewyork" component={Newyork} />
          <Route path="/thankyoumiami" component={Miami} />
          <Route path="/convergence" component={Convergence} />
          <Route path="/leaderboard" component={Leaderboard} />
          <Route path="/artheist" />
          <Route component={NotFound} />
        </Switch>
      </Container>
    </Layout>
  ) : (
    <div className="opening" style={{ display: openingDisplay }}>
      <Button
        style={{
          position: "absolute",
          width: 200,
          fontSize: 20,
          zIndex: 1,
          top: "90%",
          left: "85%",
        }}
        variant="dark"
        onClick={handleSkip}
      >
        SKIP{" "}
        <img src={NextIcon} style={{ width: 20, height: 20, color: "white" }} />
      </Button>
      <ReactPlayer
        url={Museum}
        width="100%"
        height="100%"
        playing={true}
        muted
        onEnded={handleVideoEnded}
      />
    </div>
  );
}

export default App;
