import React from "react";
import { Nav, Navbar, Image } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import logo from "../assets/icons/logo-removebg-preview.png";
import instagram from "../assets/icons/instagram.png";
import discord from "../assets/icons/discord.png";
import "../App.css";

const Menu = () => {
  const [path, setPath] = React.useState(false);

  React.useEffect(() => {
    setPath(window.location.pathname);
  }, []);

  React.useEffect(() => {
    if (typeof path.split === "function" && path.length > 1) {
      const restWord = path
        .split("/")[1]
        .split("")
        .slice(1, path.split("/")[1].length)
        .join("");
      const firstLetter = path.split("/")[1].split("")[0].toUpperCase();
      const title = firstLetter + restWord;

      document.title = `ThankYouX - NFT | ${title}`;
    } else {
      document.title = `ThankYouX - NFT`;
    }
  }, [path]);

  const handleVideo = () => {
    localStorage.removeItem("visibleVideo");
    window.location.href = window.location.origin;
  };

  return (
    <Navbar
      bg="dark"
      variant="dark"
      collapseOnSelect
      expand="lg"
      style={{ fontSize: 20 }}
    >
      <LinkContainer to="/">
        <Navbar.Brand className="d-flex align-items-center col-md-2 mb-2 mb-md-0 text-dark text-decoration-none">
          <Image
            onClick={() => handleVideo()}
            src={logo}
            style={{ width: 200, marginLeft: "20%" }}
            className="d-inline-block align-text-top"
          />
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse>
        <ul className="nav navbar-nav ms-auto w-100 justify-content-end p-3">
          <li
            className="nav-item"
            style={{
              fontWeight:
                window.location.pathname === "/convergence" ? 500 : "",
            }}
          >
            <LinkContainer
              to="/convergence"
              onClick={() => setPath("/covergence")}
            >
              <Nav.Link className="nav-link  px-2 link-secondary">
                CONVERGENCE
              </Nav.Link>
            </LinkContainer>
          </li>

          <li
            className="nav-item"
            style={{
              fontWeight:
                window.location.pathname === "/thankyoumiami" ? 500 : "",
            }}
          >
            <LinkContainer
              to="/thankyoumiami"
              onClick={() => setPath("/thankyoumiami")}
            >
              <Nav.Link className="nav-link  px-2 link-secondary">
                THANK YOU MIAMI
              </Nav.Link>
            </LinkContainer>
          </li>
          <li
            className="nav-item"
            style={{
              fontWeight:
                window.location.pathname === "/thankyounewyork" ? 500 : "",
            }}
          >
            <LinkContainer
              to="/thankyounewyork"
              onClick={() => setPath("/thankyounewyork")}
            >
              <Nav.Link className="nav-link  px-2 link-secondary">
                THANK YOU NEWYORK
              </Nav.Link>
            </LinkContainer>
          </li>

          {/* <li
            className="nav-item"
            style={{
              fontWeight: window.location.pathname === "/artheist" ? 500 : "",
            }}
          >
            <LinkContainer to="/artheist" onClick={() => setPath("/artheist")}>
              <Nav.Link className="nav-link  px-2 link-secondary">
                ART HEIST
              </Nav.Link>
            </LinkContainer>
          </li>

          <li
            className="nav-item"
            style={{
              fontWeight:
                window.location.pathname === "/leaderboard" ? 500 : "",
            }}
          >
            <LinkContainer
              to="/leaderboard"
              onClick={() => setPath("/leaderboard")}
            >
              <Nav.Link className="nav-link  px-2 link-secondary">
                LEADER BOARD
              </Nav.Link>
            </LinkContainer>
          </li>

          <li
            className="nav-item"
            style={{
              fontWeight: window.location.pathname === "/puzzle" ? 500 : "",
            }}
          >
            <LinkContainer to="/puzzle" onClick={() => setPath("/puzzle")}>
              <Nav.Link className="nav-link  px-2 link-secondary">
                PUZZLE
              </Nav.Link>
            </LinkContainer>
          </li> */}
          <li
            className="nav-item"
            style={{
              fontWeight: window.location.pathname === "/puzzle" ? 500 : "",
            }}
          >
            <Nav.Link
              href="https://www.thankyoux.com"
              target="blank"
              className="nav-link  px-2 link-secondary"
              noopener
              norefferer
            >
              FINE ART
            </Nav.Link>
          </li>
        </ul>

        <div
          style={{
            height: 30,
            borderLeft: "1px solid grey",
            marginTop: 5,
            marginRight: 10,
          }}
        ></div>
        <div className="p-3" style={{ display: "flex", flexDirection: "row" }}>
          <Nav.Link
            href="https://www.instagram.com/thankyoux/"
            target="blank"
            className="nav-link px-3 link-secondary"
            noopener
            norefferer
          >
            <Image
              className="d-inline-block align-text-top"
              src={instagram}
              width={30}
              height={30}
              className="navbar-icon"
            />
          </Nav.Link>
          <Nav.Link
            href="https://discord.gg/D83Ej4XW"
            target="blank"
            className="nav-link px-3 link-secondary"
            noopener
            norefferer
          >
            <Image
              className="d-inline-block align-text-top"
              src={discord}
              width={35}
              className="navbar-icon"
            />
          </Nav.Link>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Menu;
