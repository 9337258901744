//every key must contains file name with "/static/media/" path in front of it
//and filename must be written with lower-case.
//For example, asuming a file named the_Example_Video_File.mp4 should be written as "/static/the_example_video_file.mp4"

const informations = {
  "1avenueofthestars": {
    title: "Avenue of the Americas",
    description: "ThankYouX + JN Silva - Thank You New York Open Edition",
    link: "https://niftygateway.com/marketplace?page=1&search=&collection=0xf2129ea3cf8f356da9dbaa276773fbf8259d6690&type=1&order=asc&orderType=price&onSale=true",
    contractlink:
      "https://etherscan.io/address/0xf2129ea3cf8f356da9dbaa276773fbf8259d6690",
    contract: "0xf2129ea3cf8f356da9dbaa276773fbf8259d6690",
    download: "Download",
    token: "205 Editions",
  },

  "3citycanyons": {
    title: "City Canyons",
    description: "ThankYouX + JN Silva - Thank You New York",
    link: "https://niftygateway.com/marketplace?page=1&search=&collection=0x3a44aad55a70412b189f46852499ace9ec0d6de6&type=3&order=asc&orderType=price&onSale=true",
    contractlink:
      "https://etherscan.io/address/0x3a44aad55a70412b189f46852499ace9ec0d6de6",
    contract: "0x3a44aad55a70412b189f46852499ace9ec0d6de6",
    download: "Download",
    token: "10 Editions",
  },

  "2thegreatbridge": {
    title: "The Great Bridge",
    description: "ThankYouX + JN Silva - Thank You New York",
    link: "https://niftygateway.com/marketplace?page=1&search=&collection=0x3a44aad55a70412b189f46852499ace9ec0d6de6&type=2&order=asc&orderType=price&onSale=true",
    contractlink:
      "https://etherscan.io/address/0x3a44aad55a70412b189f46852499ace9ec0d6de6",
    contract: "0x3a44aad55a70412b189f46852499ace9ec0d6de6",
    download: "Download",
    token: "10 Editions",
  },

  "4timessquares": {
    title: "Times Squares",
    description: "ThankYouX + JN Silva - Thank You New York",
    link: "https://niftygateway.com/marketplace?page=1&search=&collection=0x3a44aad55a70412b189f46852499ace9ec0d6de6&type=1&order=asc&orderType=price&onSale=true",
    contractlink:
      "https://etherscan.io/address/0x3a44aad55a70412b189f46852499ace9ec0d6de6",
    contract: "0x3a44aad55a70412b189f46852499ace9ec0d6de6",
    download: "Download",
    token: "1 Edition",
  },
  "1hope": {
    title: "Hope",
    description: "ThankYouX - Welcome to Convergence Open Edition",
    link: "https://niftygateway.com/marketplace?page=1&search=&collection=0x5f47ba722491f5775f7b47d1b8dc927ac7326c40&type=1&order=asc&orderType=price&onSale=true",
    contractlink:
      "https://etherscan.io/address/0x5f47ba722491f5775f7b47d1b8dc927ac7326c40",
    contract: "0x5f47ba722491f5775f7b47d1b8dc927ac7326c40",
    download: "Download",
    token: "122 Editions",
  },

  "3lluviaysol": {
    title: "Lluvia y Sol",
    description: "ThankYouX + JN Silva - Thank You Miami",
    link: "https://niftygateway.com/marketplace?page=1&search=&collection=0x83713d80cd9134e35ae6861372ab90d9e449b4ab&type=1&order=asc&orderType=price&onSale=true",
    contractlink:
      "https://etherscan.io/address/0x83713d80cd9134e35ae6861372ab90d9e449b4ab",
    contract: "0x83713d80cd9134e35ae6861372ab90d9e449b4ab",
    download: "Download",
    token: "10 Editions",
  },

  "4salvavidas": {
    title: "Salvavidas",
    description: "ThankYouX + JN Silva - Thank You Miami",
    link: "https://niftygateway.com/marketplace?page=1&search=&collection=0x83713d80cd9134e35ae6861372ab90d9e449b4ab&type=2&order=asc&orderType=price&onSale=true",
    contractlink:
      "https://etherscan.io/address/0x83713d80cd9134e35ae6861372ab90d9e449b4ab",
    contract: "0x83713d80cd9134e35ae6861372ab90d9e449b4ab",
    download: "Download",
    token: "1 Edition",
  },

  "1thegreatescape": {
    title: "The Great Escape",
    description: "ThankYouX + JN Silva - Thank You Miami Open Edition",
    link: "https://niftygateway.com/marketplace?page=1&search=&collection=0xea70a9e62057dd7629e7c9ca7500290544d13e56&type=1&order=asc&orderType=price&onSale=true",
    contractlink:
      "https://etherscan.io/address/0xea70a9e62057dd7629e7c9ca7500290544d13e56",
    contract: "0xea70a9e62057dd7629e7c9ca7500290544d13e56",
    download: "Download",
    token: "304 Editions",
  },

  "2suspensionofdisbelief": {
    title: "Suspension of Belief",
    description: "ThankYouX + JN Silva - Thank You Miami Open Edition",
    link: "https://niftygateway.com/marketplace?page=1&search=&collection=0xea70a9e62057dd7629e7c9ca7500290544d13e56&type=2&order=asc&orderType=price&onSale=true",
    contractlink:
      "https://etherscan.io/address/0xea70a9e62057dd7629e7c9ca7500290544d13e56",
    contract: "0xea70a9e62057dd7629e7c9ca7500290544d13e56",
    download: "Download",
    token: "123 Editions",
  },

  "2control": {
    title: "Control",
    description: "ThankYouX - Welcome to Convergence",
    link: "https://niftygateway.com/marketplace?page=1&search=&collection=0xdc89669bb478506078ea5c1644bbb09e072474e7&type=2&order=asc&orderType=price&onSale=true",
    contractlink:
      "https://etherscan.io/address/0xdc89669bb478506078ea5c1644bbb09e072474e7",
    contract: "0xdc89669bb478506078ea5c1644bbb09e072474e7",
    download: "Download",
    token: "50 Editions",
  },

  "3power": {
    title: "Power",
    description: "ThankYouX - Welcome to Convergence",
    link: "https://niftygateway.com/marketplace?page=1&search=&collection=0xdc89669bb478506078ea5c1644bbb09e072474e7&type=1&order=asc&orderType=price&onSale=true",
    contractlink:
      "https://etherscan.io/address/0xdc89669bb478506078ea5c1644bbb09e072474e7",
    contract: "0xdc89669bb478506078ea5c1644bbb09e072474e7",
    download: "Download",
    token: "50 Editions",
  },
  "4mercy": {
    title: "Mercy",
    description: "ThankYouX - Welcome to Convergence",
    link: "https://niftygateway.com/marketplace?page=1&search=&collection=0xdc89669bb478506078ea5c1644bbb09e072474e7&type=7&order=asc&orderType=price&onSale=true",
    contractlink:
      "https://etherscan.io/address/0xdc89669bb478506078ea5c1644bbb09e072474e7",
    contract: "0xdc89669bb478506078ea5c1644bbb09e072474e7",
    download: "Download",
    token: "1 Edition",
  },
  "5newdirection": {
    title: "New Direction",
    description: "ThankYouX - Welcome to Convergence",
    link: "https://niftygateway.com/marketplace?page=1&search=&collection=0xdc89669bb478506078ea5c1644bbb09e072474e7&type=5&order=asc&orderType=price&onSale=true",
    contractlink:
      "https://etherscan.io/address/0xdc89669bb478506078ea5c1644bbb09e072474e7",
    contract: "0xdc89669bb478506078ea5c1644bbb09e072474e7",
    download: "Download",
    token: "1 Edition",
  },
  "8withinadream": {
    title: "Within A Dream",
    description: "ThankYouX - Welcome to Convergence",
    link: "https://niftygateway.com/marketplace?page=1&search=&collection=0xdc89669bb478506078ea5c1644bbb09e072474e7&type=4&order=asc&orderType=price&onSale=true",
    contractlink:
      "https://etherscan.io/address/0xdc89669bb478506078ea5c1644bbb09e072474e7",
    contract: "0xdc89669bb478506078ea5c1644bbb09e072474e7",
    download: "Download",
    token: "1 Edition",
  },
  "6somethingreal": {
    title: "Something Real",
    description: "ThankYouX - Welcome to Convergence",
    link: "https://niftygateway.com/marketplace?page=1&search=&collection=0xdc89669bb478506078ea5c1644bbb09e072474e7&type=3&order=asc&orderType=price&onSale=true",
    contractlink:
      "https://etherscan.io/address/0xdc89669bb478506078ea5c1644bbb09e072474e7",
    contract: "0xdc89669bb478506078ea5c1644bbb09e072474e7",
    download: "Download",
    token: "1 Edition",
  },
  "7tomorrow": {
    title: "Tomorrow",
    description: "ThankYouX - Welcome to Convergence",
    link: "https://niftygateway.com/marketplace?page=1&search=&collection=0xdc89669bb478506078ea5c1644bbb09e072474e7&type=6&order=asc&orderType=price&onSale=true",
    contractlink:
      "https://etherscan.io/address/0xdc89669bb478506078ea5c1644bbb09e072474e7",
    contract: "0xdc89669bb478506078ea5c1644bbb09e072474e7",
    download: "Download",
    token: "1 Edition",
  },
};

export default informations;
