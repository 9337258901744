import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { connectWallet } from "../utils/interact";

const Wallet = () => {
  //State variables
  const [isConnected, setConnectedStatus] = useState(false);
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    fetchData();
  });

  async function fetchData() {
    if (window.ethereum) {
      //if Metamask installed
      try {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        }); //get Metamask wallet
        if (accounts.length) {
          //if a Metamask account is connected
          setConnectedStatus(true);
          setWallet(accounts[0]);
        } else {
          setConnectedStatus(false);
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      } catch {
        setConnectedStatus(false);
        if (typeof setStatus === "function") {
          setStatus(
            "🦊 Connect to Metamask using the top right button. " +
              walletAddress
          );
        }
      }
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    if (walletResponse) {
      setConnectedStatus(walletResponse.connectedStatus);
      setStatus(walletResponse.status);
    }

    if (isConnected) {
      setWallet(walletAddress);
    }
  };

  return (
    <div className="Minter">
      <Button
        class="btn btn-light"
        id="walletButton"
        onClick={connectWalletPressed}
        style={{ width: "max-content" }}
      >
        {isConnected ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>LOGIN WITH METAMASK</span>
        )}
      </Button>
    </div>
  );
};
export default Wallet;
