import BootstrapTable from 'react-bootstrap-table-next';
import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import Header from '../components/Header';
import Meta from '../components/Meta';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      winners: [],
      showModal: false,
      scores: [],
    };

    this.handleClose = this.handleClose.bind(this);
    this.onGetScores = this.onGetScores.bind(this);
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/seeds`)
    .then(response => {
      return response.json();
    })
    .then((data) => {
      let winners = [];
      let index = 0;
      data.map((obj) => {
        let day = obj.timestamp.slice(0, 10);
        obj.timestamp = day;
        // show only winners
        if (obj.is_winner) {
          index = index + 1;
          obj.index = index;
          winners.push(obj);
        }
      })
       this.setState({ winners });
    });
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  onGetScores = (cell, row) => (e) => {
    this.setState({ showModal: true });
    fetch(`${process.env.REACT_APP_API_URL}/seeds/${row.username}`)
      .then(response => {
        return response.json();
      })
      .then((data) => {
        this.setState({ scores: data });
      });
  }

  render() {
    const columns = [{
      dataField: 'index',
      text: 'Id',
      sort: true,
    }, {
      dataField: 'username',
      text: 'Username',
      sort: true,
    }, {
      dataField: 'score',
      text: 'Score',
      sort: true,
    }, {
      dataField: 'timestamp',
      text: 'Puzzle Day',
      sort: true,
    }, {
      dataField: 'id',
      text: 'Total',
      formatter: (cellContent, row) => {
        return (
          <Button id="data" onClick={this.onGetScores(cellContent, row)} >
            Show Total Scores
          </Button>
        );
      }
    }];

    const columnScores = [{
      dataField: 'username',
      text: 'Username',
    }, {
      dataField: 'scoreDay1',
      text: 'Puzzle day 1',
    }, {
      dataField: 'scoreDay2',
      text: 'Puzzle day 2',
    }, {
      dataField: 'scoreDay3',
      text: 'Puzzle day 3',
    }, {
      dataField: 'totalScore',
      text: 'Total Score',
    }];

    return (
      <div className="App">
        <Meta title="Leader Board" />
        <Header head="Leader Board"  description='' />
        <ToolkitProvider
          keyField="id"
          data={ this.state.winners }
          columns={ columns }
          search={true}
          exportCSV={{
            fileName: 'leaderboard.csv'
          }}
          >
            {
              props => (
                <div>
                  <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                    <SearchBar
                      { ...props.searchProps}
                      srText= ''
                    />
                  </div>
                  <BootstrapTable
                    style={{ float: "left" }}
                    bordered={false}
                    { ...props.baseProps }
                    pagination={ paginationFactory() }
                  />
                  <Modal show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Header>Scores for Puzzle days: </Modal.Header>
                    <Modal.Body>
                      <BootstrapTable keyField='username' data={ this.state.scores } columns={ columnScores } />
                    </Modal.Body>
                  </Modal>
                </div>
              )
            }
          </ToolkitProvider>
       </div>
    );
  }
}
