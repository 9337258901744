import { Card, CardGroup, CardImg, Row, Container } from "react-bootstrap";
import React from "react";
import "../App.css";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import informations from "../assets/informations";
import volumeIcon from "../assets/icons/volume.png";
import muteIcon from "../assets/icons/mute.png";
import nimg from "../assets/icons/nimg.jpeg";
import etherscan from "../assets/icons/etherscan-logo-circle.png";

function importAll(r) {
  let videos = {};
  r.keys().map((item, index) => {
    videos[item.replace("./", "")] = r(item).default;
  });
  return videos;
}

const videos = importAll(
  require.context("../assets/lowresvideos/convergence", false, /\.(mp4)$/)
);

function importAllImages(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item).default;
  });
  return images;
}

const images = importAllImages(
  require.context("../assets/stills/convergence", false, /\.(jpg)$/)
);

const Convergence = () => {
  const [showControls, setShowControls] = React.useState(false);
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [visibleInfo, setVisibleInfo] = React.useState(null);
  const sliderRef = React.useRef();

  const settings = {
    dots: true,
    infinite: false,
    afterChange: (current) => setActiveSlide(current),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const [mute, setMute] = React.useState(true);

  const toggleControls = () => setShowControls(!showControls);

  const handleClickCard = (number) => {
    sliderRef.current.slickGoTo(number);

    if (window.location.hash === "#slicks") {
      window.location.href = window.location.href;
    } else {
      window.location.href = window.location.href + "#slicks";
    }
  };

  return (
    <>
      <section
        style={{
          minHeight: "100vh",
          paddingTop: "5%",
        }}
      >
        <Container>
          <Row>
            <header style={{ textAlign: "center", marginBottom: "5%" }}>
              <h1>CONVERGENCE - BY THANKYOUX</h1>
            </header>
            <CardGroup>
              {Object.values(images).map((image, key) => (
                <Card
                  style={{ margin: "1%", cursor: "pointer", minWidth: 250 }}
                  onClick={() => handleClickCard(key)}
                  onMouseEnter={() => setVisibleInfo(key)}
                  onMouseLeave={() => setVisibleInfo(null)}
                >
                  <CardImg src={image} />
                  <Card.Text
                    style={{
                      display: visibleInfo === key ? "flex" : "none",
                      position: "absolute",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "transparent",
                      width: "100%",
                      height: "100%",
                      fontSize: 25,
                      opacity: "1 !important",
                    }}
                  >
                    {Object.keys(informations).map((key) =>
                      key ===
                      image.split(".")[0].split("/")[3].toLowerCase() ? (
                        <div
                          className="information-body"
                          style={{
                            backgroundColor: "res",
                            fontSize: 20,
                            textAlign: "start",
                            marginLeft: 20,
                            width: "80%",
                            padding: "5%",
                            overflowWrap: "break-word",
                          }}
                        >
                          <div className="information-title">
                            <p>{informations[key]?.title}</p>
                          </div>

                          <div
                            style={{
                              fontSize: 18,
                              textAlign: "start",
                              marginLeft: 0,
                            }}
                          >
                            <p>{informations[key]?.description}</p>
                          </div>

                          <p>{informations[key]?.token}</p>
                        </div>
                      ) : null
                    )}
                  </Card.Text>
                </Card>
              ))}
            </CardGroup>
          </Row>
        </Container>
      </section>
      <section
        id="slicks"
        style={{
          minHeight: "100vh",
          paddingTop: "12%",
        }}
      >
        <div
          style={{
            paddingTop: "5%",
            paddingBottom: "5%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            backgroundColor: "rgb(246, 246, 246)",
          }}
        >
          <div style={{ display: "block", width: "80%" }}>
            <div className="slider-wrapper">
              <Slider {...settings} ref={sliderRef}>
                {Object.values(videos).map((video, index) => (
                  <div className="video-wrapper">
                    <ReactPlayer
                      url={video}
                      muted={mute}
                      loop={index === activeSlide}
                      playing={index === activeSlide}
                      className="nft-video"
                      style={{
                        cursor: "pointer",
                        outline: 0,
                        border: "none",
                      }}
                      width="100%"
                      height="100%"
                      onClick={() => toggleControls()}
                    />
                    <div
                      class="volume-button"
                      style={{
                        backgroundImage: mute
                          ? `url(${muteIcon})`
                          : `url(${volumeIcon})`,
                      }}
                      onClick={() => setMute(!mute)}
                    ></div>
                    {Object.keys(informations).map((key) =>
                      key ===
                      video.split(".")[0].split("/")[3].toLowerCase() ? (
                        <div
                          className="information-body"
                          style={{
                            backgroundColor: "res",
                            fontSize: 20,
                            textAlign: "start",
                            margin: "0 auto",
                            marginLeft: 20,
                            width: "100%",
                          }}
                        >
                          <div className="information-title">
                            <p>{informations[key]?.title}</p>
                          </div>

                          <div
                            style={{
                              fontSize: 18,
                              textAlign: "start",
                              marginLeft: 0,
                            }}
                          >
                            <p>{informations[key]?.description}</p>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              fontSize: 18,
                            }}
                          >
                            <img
                              alt=""
                              src={nimg}
                              style={{
                                width: 25,
                                margin: "0 10px 0 10px",
                                textOverflow: "ellipsis",
                              }}
                            />
                            <a
                              href={informations[key]?.link}
                              target="_blank"
                              rel="noreferrer"
                              className="information-link"
                            >
                              Nifty Gateway Marketplace
                            </a>
                          </div>
                          <p></p>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              fontSize: 18,
                            }}
                          >
                            <img
                              alt=""
                              src={etherscan}
                              style={{
                                width: 25,
                                margin: "0 10px 0 10px",
                                textOverflow: "ellipsis",
                              }}
                            />
                            <a
                              href={informations[key]?.contractlink}
                              target="_blank"
                              rel="noreferrer"
                              className="information-link"
                            >
                              {informations[key]?.contract}
                            </a>
                          </div>
                          <p></p>
                          <p></p>
                          <p>{informations[key]?.token}</p>
                        </div>
                      ) : null
                    )}
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Convergence;
