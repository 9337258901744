import React from "react";
import Image from "react-image-enlarger";
import { Container, Col } from "react-bootstrap";

function importAll(r) {
  let cards = {};
  r.keys().map((item, index) => {
    cards[item.replace("./", "")] = r(item).default;
  });
  return cards;
}


function ImageSource({ src }) {
  const [zoomed, setZoomed] = React.useState(false);

  return (
      <Image
        style={{  width: 100, margin: "0 10px 0 10px",}}
        zoomed={zoomed}
        src={src}
        onClick={() => setZoomed(true)}
        onRequestClose={() => setZoomed(false)}
      />
  );
}

function PuzzleCards({ index }) {
  let cards;
  let title;
  if (index === "1") {
    cards = importAll(
      require.context("../assets/puzzle_day1", false, /\.(jpg)$/)
    );
    title = "Puzzle Day One";
  } else if (index === "2") {
    cards = importAll(
      require.context("../assets/puzzle_day2", false, /\.(jpg)$/)
    );
    title = "Puzzle Day Two";
   } else if (index === "3") {
    cards = importAll(
      require.context("../assets/puzzle_day3", false, /\.(jpg)$/)
    );
    title = "Puzzle Day Three";
   }

  return (
    <Container>
      <div style={{ textAlign: "center", padding: '1%' }} >
        <h5>{title}</h5>
        <Col>
          <div
            className="d-flex justify-content-center"
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: 18,
            }}
          >
            {Object.values(cards).map((card, index) => (
              <ImageSource key={index} src={card} title={title} />
            ))}
          </div>
        </Col>
      </div>
    </Container>
  );
}


export default PuzzleCards;
